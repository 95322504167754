@tailwind utilities;


.core-checkbox[type="checkbox"] {
  @apply border border-zinc-300 ring-0 outline-1;
  box-shadow: none;
}

.core-checkbox[type="checkbox"]:hover,
.core-checkbox[type="checkbox"]:active,
.core-checkbox[type="checkbox"]:focus {
  @apply border border-zinc-900 ring-0 outline-1;
  box-shadow: none;
}


.core-checkbox[type="checkbox"]:checked,
.core-checkbox[type="checkbox"]:checked:hover,
.core-checkbox[type="checkbox"]:checked:active,
.core-checkbox[type="checkbox"]:checked:focus {
  @apply border border-zinc-900 ring-0 outline-0;
  background-color: transparent;
  background-size:70% 70%;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  box-shadow: none;
}