.circle {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation-delay: 4s;
  animation: grow .35s ease-in-out;
}

@keyframes grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    width: 48px;
    height: 48px;
    opacity: 1;
  }
}
