@keyframes button-loading-animation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.button-primary-loading-bg {
  background-image: linear-gradient(90deg, #3B3E3C 0%, #3B3E3C 50%, #171817 50%, #171817 100%) !important;
  background-size: 200% 200%;
  animation: button-loading-animation 2s ease infinite;
}

.button-primary-loading-bg-inverted {
  background-image: linear-gradient(90deg, #D8D9D8 0%, #D8D9D8 50%, #F9FAF9 50%, #F9FAF9 100%) !important;
  background-size: 200% 200%;
  animation: button-loading-animation 2s ease infinite;
}

.button-secondary-loading-bg {
  background-image: linear-gradient(90deg, #EEEFEE 0%, #EEEFEE 50%, #F9FAF9 50%, #F9FAF9 100%) !important;
  background-size: 200% 200%;
  animation: button-loading-animation 2s ease infinite;
}

.button-secondary-loading-bg-inverted {
  background-image: linear-gradient(90deg, #3B3E3C 0%, #3B3E3C 50%, #171817 50%, #171817 100%) !important;
  background-size: 200% 200%;
  animation: button-loading-animation 2s ease infinite;
}

.button-destruction-loading-bg {
  background-image: linear-gradient(90deg, #FA2C28 0%, #FA2C28 50%, #C82320 50%, #C82320 100%) !important;
  background-size: 200% 200%;
  animation: button-loading-animation 2s ease infinite;
}

